<template>
  <div class="layout-main">
    <section class="account">

      <ColumnLayout :router-name="$route.name">
        <template v-slot:column-one>
          <div class="pannel-card info-nav d-none d-md-flex justify-content-center mb-0">
            <div class="fs-h5">我的帳戶</div>
          </div>

          <div class="pannel-card info-group">
<!--            <div class="info-item">-->
<!--              <div class="d-flex justify-content-between align-items-center">-->
<!--                <div class="fs-body-16">揪幣</div>-->
<!--                <div class="fs-h5">NT${{ accountInfo.points.toLocaleString('en-US') }}</div>-->
<!--              </div>-->
<!--              <div class="withdraw-btn d-flex justify-content-end d-md-none" v-if="withdrawState">-->
<!--                <div class="fs-body-14" @click="toggleMobileDetail">提領</div>-->
<!--              </div>-->
<!--              <div class="withdraw-msg fs-body-16 color-warning">提領的款項將於申請日「次星期一」匯至您設定的帳戶中。</div>-->
<!--            </div>-->
            <div class="info-item">
              <div class="d-flex justify-content-between align-items-center">
                <div class="fs-body-16">揪點</div>
                <div class="fs-h5">{{ accountInfo.bonusPoints }} 點</div>
              </div>
            </div>

<!--            <div class="info-item sub-title">-->
<!--              <div class="fs-body-16">款項紀錄</div>-->
<!--            </div>-->

<!--            <TransactionLog-->
<!--                v-for="(item) in transactionLog"-->
<!--                :key="item.serial"-->
<!--                :transaction-info="item"-->
<!--            ></TransactionLog>-->

          </div>
        </template>
        <template v-slot:column-two>
<!--          <div class="pannel-card detail-group mobile-detail custom-z-index" :class="{'mobile-show': showMobileDetail}" v-if="withdrawState">-->
<!--            <div class="detail-item mobile-sidebar-nav custom-z-index d-md-flex justify-content-center">-->
<!--              <div class="prev-btn d-md-none" @click="toggleMobileDetail">-->
<!--                <img :src="require('@/assets/images/svgs/common/prev-btn.svg')" alt="prev-btn">-->
<!--              </div>-->
<!--              <div class="fs-h5">提領</div>-->
<!--            </div>-->

<!--            <div class="detail-item full-screen d-flex flex-column justify-content-between">-->
<!--              <div class="d-flex flex-column">-->
<!--                <div class="fs-title-16">-->
<!--                  填寫完成後按確認匯出，系統會將「全部」揪幣扣除 NT$15 匯款手續費後，於申請日「次星期一」匯至您下方輸入的帳戶。-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                  <label class="w-100 d-flex justify-content-between align-items-center" for="bankCode">-->
<!--                    <span class="fs-title-14">銀行代碼</span>-->
<!--                    <span class="fs-capation-12 color-warning">必填</span>-->
<!--                  </label>-->
<!--                  <input type="text" class="form-control fs-body-16" id="bankCode"  v-model="accountInfo.bankCode">-->
<!--                </div>-->

<!--                <div class="form-group">-->
<!--                  <label class="w-100 d-flex justify-content-between align-items-center title" for="bankAccount">-->
<!--                    <span class="fs-title-14">銀行帳號</span>-->
<!--                    <span class="fs-capation-12 color-warning">必填</span>-->
<!--                  </label>-->
<!--                  <input type="text" class="form-control fs-body-16" id="bankAccount" v-model="accountInfo.bankAccount">-->
<!--                </div>-->

<!--                <div class="form-group checkbox d-flex justify-content-between align-items-center">-->
<!--                  <CustomCheckbox :checked-title="'記住此帳號資訊'" v-model="rememberMe"></CustomCheckbox>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="btn common-btn dark fs-btn-md account" @click="setShowRequestModal">確認匯出</div>-->
<!--            </div>-->

<!--          </div>-->
        </template>
      </ColumnLayout>

    </section>
<!--    <b-modal ref="request-modal" title="提領" centered v-model="showRequestModal">-->
<!--      <div>-->
<!--        <div>請確認是否將揪幣全額提領至以下銀行帳戶？</div>-->
<!--        <br>-->
<!--        <div>銀行代碼：{{ accountInfo.bankCode }}</div>-->
<!--        <div>銀行帳號：{{ accountInfo.bankAccount }}</div>-->
<!--      </div>-->

<!--      <template #modal-footer>-->
<!--        <div class="d-flex justify-content-between w-100">-->
<!--          <div class="col">-->
<!--            <button class="btn common-btn dark btn-block" @click="acceptRequest">確定</button>-->
<!--          </div>-->
<!--          <div class="col">-->
<!--            <button class="btn common-btn btn-block" @click="rejectRequest">取消</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
<!--    </b-modal>-->
  </div>
</template>

<script>
import ColumnLayout from '@/components/common/ColumnLayout';
// import CustomCheckbox from "@/components/common/CustomCheckbox";
import {mapActions} from "vuex";
// import TransactionLog from "@/views/account/TransactionLog";
import moment from 'moment';
moment.locale('zh-tw');

export default {
  name: 'Account',
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',
      accountInfo: {},
      transactionLog: [],
      withdrawList: [],
      showMobileDetail: false,
      rememberMe: false,
      showRequestModal: false,
    };
  },
  props: {
  },
  components: {
    ColumnLayout,
    // CustomCheckbox,
    // TransactionLog,
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
    withdrawState() {
      return this.accountInfo.points >= 50
    },
    bankInfoState() {
      return (this.accountInfo.bankCode !== '' && this.accountInfo.bankAccount !== '')
    }
	},
  watch: {
    
  },
  async mounted() {
    await this.refresh();
	},
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),

    __numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // async refreshWithdrawList() {
    //   this.withdrawList.splice(0, this.withdrawList.length);
    //   const res = await this.$store.dispatch('api/getWithdrawListPromise');
    //   for (const w of res.list) {
    //     this.withdrawList.push(w);
    //   }
    // },
    async refresh() {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        // const log = await this.$store.dispatch('api/getTransactionLogPromise');
        // console.log(log)
        // log.map((l) => {
        //   if (l.status === 2 || l.status === 3) {
        //     const statusStr = l.catchRequest.status === 9?'取消': l.catchRequest.status === 6?'完成': '預訂';
        //     const statusDateStr = (moment(l.updated_at).year() - 1911) + '/' + moment(l.updated_at).format('MM/DD (dd)');
        //
        //     const descName = l.journey.name;
        //     const descTime = (l.journey.startDate === l.journey.endDate)? moment(l.journey.startDate).format('YYYY/MM/DD(dd)') : moment(l.journey.startDate).format('YYYY/MM/DD(dd)') + ' ~ ' + moment(l.journey.endDate).format('YYYY/MM/DD(dd)');
        //     const descAmount = '總金額 $' + this.__numberWithCommas(l.requestAmount);
        //     console.log(123)
        //     const descPayAry = [];
        //     if (l.usedPoints > 0) {
        //       descPayAry.push(`揪幣 ${this.__numberWithCommas(l.usedPoints)}`);
        //     }
        //     if (l.usedBonusPoints > 0) {
        //       descPayAry.push(`揪點 ${this.__numberWithCommas(l.usedBonusPoints)}`);
        //     }
        //     if (l.confirmedAmount > 0) {
        //       descPayAry.push(`匯款 NT$${this.__numberWithCommas(l.confirmedAmount)}`);
        //     }
        //     const descPay = descPayAry.length>0? descPayAry.join(' / '): '';
        //
        //     const desc = `${descName}<br/>${descTime}<br/>${descAmount}${descPay.length > 0? '<br/>' + descPay: ''}`;
        //
        //     this.transactionLog.push({
        //       title: `${statusStr} | ${statusDateStr}`,
        //       updated_at: l.updated_at,
        //       desc,
        //     });
        //   }
        // });
        // this.transactionLog = this.transactionLog.sort((a, b) => {
        //   return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
        // });
        // console.log(this.transactionLog)
        this.accountInfo = await this.$store.dispatch('api/getAccountInfoPromise');
        // await this.refreshWithdrawList();
        // this.rememberMe = this.bankInfoState;
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    toggleMobileDetail() {
      this.showMobileDetail = !this.showMobileDetail;
    },
    setShowRequestModal() {
      if(this.bankInfoState) {
        this.showRequestModal = true;
      } else {
        this.appendErrorMsg('請輸入銀行代碼以及銀行帳號');
      }
    },
    async acceptRequest() {
      try {
        await this.$store.dispatch('api/postWithdrawSubmitPromise', {
          // amount: this.accountInfo.points,
          amount: 100, // test
          bankCode: this.accountInfo.bankCode,
          bankAccount: this.accountInfo.bankAccount,
          saveAccount: this.rememberMe,
        });
        await this.refresh();
        this.showRequestModal = false;
        this.showMobileDetail = false;
      } catch (error) {
        console.error(error);
      }
    },
    rejectRequest() {
      this.showRequestModal = false;
      this.showMobileDetail = false;
    },
  }
}
</script>


<style lang="scss" scoped>
  @import "src/assets/scss/basic";
</style>
